<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.69775 2.08767C9.69775 2.32347 9.61065 2.55926 9.43585 2.73918L5.29541 7.00008L9.43584 11.261C9.78486 11.6208 9.78486 12.2042 9.43584 12.564C9.08623 12.9232 8.51946 12.9232 8.16985 12.564L3.39701 7.65159C3.048 7.29176 3.048 6.70841 3.39701 6.34857L8.16985 1.43616C8.51946 1.07694 9.08624 1.07694 9.43585 1.43616C9.61065 1.61608 9.69775 1.85188 9.69775 2.08767Z"
      fill="white"
    />
  </svg>
</template>
