<template>
  <NuxtLink
    :to="localePath(path)"
    :class="$style['nav-link']"
  >
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
import { normalize } from "~/utils/url/normalize";

const props = defineProps<{ to: string }>();
const localePath = useLocalePath();
const path = computed(() => normalize(`/${props.to}`));
</script>

<style lang="scss" module>
.nav-link {
  text-decoration: none;
}
</style>
